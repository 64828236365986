import { Route, Routes } from "react-router-dom";
import {
  About,
  Checklist,
  Collection,
  Feats,
  Hero,
  Home,
  ImportIndex,
  ManageBanner,
  ManageBanners,
  ManageHero,
  ManageHeroes,
  ManageIndex,
  Settings,
} from "../../pages";
import { useNavigation } from "../../reducer/hooks";

export const Routing = () => {
  const { showManageAreas } = useNavigation();

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/Hero/:id" element={<Hero />} />

      <Route path="/Collection" element={<Collection />} />

      <Route path="/Checklist" element={<Checklist />} />

      <Route path="/Feats" element={<Feats />} />

      <Route path="/Manage" element={<ManageIndex />} />

      {showManageAreas ? (
        <>
          <Route path="/Manage/Heroes" element={<ManageHeroes />} />
          <Route path="/Manage/Hero" element={<ManageHero />} />
          <Route path="/Manage/Hero/:id" element={<ManageHero />} />
          <Route path="/Manage/Banners" element={<ManageBanners />} />
          <Route path="/Manage/Banner" element={<ManageBanner />} />
          <Route path="/Manage/Banner/:id" element={<ManageBanner />} />
          <Route path="/Manage/Import" element={<ImportIndex />} />
        </>
      ) : (
        <></>
      )}

      <Route path="/About" element={<About />} />
      <Route path="/Settings" element={<Settings />} />
    </Routes>
  );
};
