import "./ProgressBar.scss";

type ProgressBarProps = {
  count: number;
  maximum: number;
  size?: "large" | "small";
  label?: boolean;
};

function ProgressBar({ count, maximum, size, label }: ProgressBarProps) {
  size ??= "large";
  label ??= true;

  const percentage = Math.min(
    Math.max(!!maximum ? (count / maximum) * 100 : 0, 0),
    100,
  ).toFixed(2);

  const classes = `progress-bar__outer progress-bar__outer--${size}`;

  return (
    <div className={classes}>
      {!!maximum && (
        <>
          <div
            className="progress-bar__inner"
            style={{ width: `${percentage}%` }}
          ></div>
          <div className="progress-bar__text-container">
            {label && (
              <span className="progress-bar__text">
                {Math.min(Math.max(count, 0), maximum)}/{maximum} ({percentage}
                %)
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ProgressBar;
