import { useState } from "react";
import {
  getCollectionCount,
  isInCollection,
} from "../../helpers/collectionHelper";
import HeroSummoningPoolIds from "../../lookups/HeroSummoningPoolIds";
import { useCollection, useSiteData } from "../../reducer/hooks";
import HeroInfo from "../../types/HeroInfo";
import ProgressBar from "../ProgressBar";
import "./StatsPane.scss";

type StatsPaneProps = {};

const StatsPane = ({}: StatsPaneProps) => {
  const { heroes } = useSiteData();
  const { collection } = useCollection();

  const [showFullStats, setShowFullStats] = useState(false);

  const writeStatsLine = (
    title: string,
    slicer: (hero: HeroInfo) => boolean,
    indent: number,
  ) => {
    const heroesForLine = heroes.filter(slicer);
    const maxCount = heroesForLine.length;
    const count = heroesForLine.filter((h) =>
      isInCollection(h.shortId, collection),
    ).length;

    return (
      <StatsLine
        title={title}
        count={count}
        maxCount={maxCount}
        indent={indent}
      />
    );
  };

  // Progress bar data
  const collectionCount = getCollectionCount(collection);
  const heroCount = heroes.length;

  return (
    <>
      <div className="stats-heading">
        <h3>Collection Completion</h3>
        <button onClick={() => setShowFullStats(!showFullStats)}>
          {showFullStats ? "Hide" : "Show more"}
        </button>
      </div>
      {!showFullStats ? (
        <ProgressBar count={collectionCount} maximum={heroCount} />
      ) : (
        <div>
          {writeStatsLine("Overall", (hero) => true, 0)}

          {writeStatsLine(
            "Common Pool",
            (hero) =>
              (
                [
                  HeroSummoningPoolIds.FiveStarGeneral,
                  HeroSummoningPoolIds.FiveStarLegacy,
                  HeroSummoningPoolIds.ThreeFourStar,
                ] as number[]
              ).includes(hero.heroSummoningPool.id),
            1,
          )}
          {writeStatsLine(
            "Seasonals",
            (hero) =>
              (
                [
                  HeroSummoningPoolIds.FiveStarSeasonal,
                  HeroSummoningPoolIds.FiveStarSeasonalLegacy,
                  HeroSummoningPoolIds.ThreeFourStarSeasonal,
                ] as number[]
              ).includes(hero.heroSummoningPool.id),
            1,
          )}
          {writeStatsLine(
            "Grand Hero Battles/Tempest Trials",
            (hero) =>
              ([HeroSummoningPoolIds.Grail] as number[]).includes(
                hero.heroSummoningPool.id,
              ),
            1,
          )}
          {writeStatsLine(
            "Legendary/Mythic/Emblem",
            (hero) =>
              (
                [
                  HeroSummoningPoolIds.LegendaryMythicEmblem,
                  HeroSummoningPoolIds.LegendaryMythicRemixed,
                  HeroSummoningPoolIds.LegendaryRevival,
                ] as number[]
              ).includes(hero.heroSummoningPool.id),
            1,
          )}
          {writeStatsLine(
            "Rearmed/Attuned/Aided",
            (hero) =>
              (
                [
                  HeroSummoningPoolIds.Rearmed,
                  HeroSummoningPoolIds.Attuned,
                  HeroSummoningPoolIds.Aided,
                ] as number[]
              ).includes(hero.heroSummoningPool.id),
            1,
          )}
        </div>
      )}
    </>
  );

  // By seasonal theme?
  // By book?
  // By game?

  // Completed banners?

  // Game stats? (Days since launch, number of heroes)

  // Try not to overlap too much with Feats
};

type StatsLineProps = {
  title: string;
  count: number;
  maxCount: number;
  indent: number;
};

const StatsLine = ({ title, count, maxCount, indent }: StatsLineProps) => {
  let className = "stats-line";
  if (indent > 0) {
    className += " stats-line--indent-" + indent;
  }

  return (
    <div className={className}>
      <div className="stats-line__text-container">
        <label>{title}</label>
        {/* <span>{count}/{maxCount} ({((count / maxCount) * 100).toFixed(1)}%)</span> */}
      </div>
      <div className="stats-line__progress-container">
        <ProgressBar
          count={count}
          maximum={maxCount}
          size="large"
          label={true}
        />
      </div>
    </div>
  );
};

export default StatsPane;
