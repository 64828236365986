import { useEffect, useState } from "react";
import { getScrapeList } from "../../helpers/apiHelper";
import ImportableHero from "../../types/ImportableHero";
import FeedbackType from "../../types/Feedback";
import Feedback from "../../components/Feedback";
import ScrapeRequest from "../../types/ScrapeRequest";
import Modal from "../../components/Modal/Modal";
import ImportAdditionalInfo from "../../types/ImportAdditionalInfo";
import { useAdmin, useDispatch, useSiteData } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";
import TextDataField from "../../components/TextDataField";
import DatepickerDataField from "../../components/DatepickerDataField";
import SelectDataField from "../../components/SelectDataField";
import { SelectDataFieldItem } from "../../components/SelectDataField/SelectDataField";

const defaultImportAdditionalInfo = {
  externalId: 0,
  shortId: "",
};

const linkOrImportTurnPoint = 462189;

const ImportIndex = () => {
  const siteData = useSiteData();
  const { adminPassword } = useAdmin();
  const dispatch = useDispatch();

  const [scrapeList, setScrapeList] = useState<ImportableHero[]>([]);
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);

  const [importAdditionalInfo, setImportAdditionalInfo] =
    useState<ImportAdditionalInfo>({ ...defaultImportAdditionalInfo });

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      loading: loading,
    });
  };

  useEffect(() => {
    setLoading(true);

    if (!adminPassword || !siteData?.heroes?.length) {
      setLoading(false);
      return;
    }

    getScrapeList(adminPassword)
      .then((data) => {
        const filteredData = data
          .filter(
            (x) =>
              siteData.heroes.filter((y) => y.externalSiteId === x.externalId)
                .length === 0,
          )
          .sort(
            (a, b) =>
              (b.externalId ?? Number.MAX_SAFE_INTEGER) -
              (a.externalId ?? Number.MAX_SAFE_INTEGER),
          );

        setScrapeList(filteredData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [adminPassword, siteData]);

  const linkExistingHero = async (hero: ImportableHero) => {
    await scrapeAction(hero, `/Scrape/LinkExisting/${hero.externalId}`, "Link");
  };

  const importNewHero = async (hero: ImportableHero) => {
    const data: ScrapeRequest = {
      shortId: importAdditionalInfo.shortId,
      externalId: importAdditionalInfo.externalId,
      scrapeData: true,
      scrapeImages: true,

      themeId: !!importAdditionalInfo.themeId
        ? importAdditionalInfo.themeId
        : undefined,
      firstAvailableDate: !!importAdditionalInfo.firstAvailableDate
        ? importAdditionalInfo.firstAvailableDate
        : undefined,
      backupCharacterName: !!importAdditionalInfo.backupCharacterName
        ? importAdditionalInfo.backupCharacterName
        : undefined,
      backupCharacterGame: !!importAdditionalInfo.backupCharacterGame
        ? importAdditionalInfo.backupCharacterGame
        : undefined,
    };

    await scrapeAction(hero, `/Scrape/Hero`, "Import", data);
  };

  const scrapeAction = async (
    hero: ImportableHero,
    apiPath: string,
    description: "Link" | "Import",
    body: ScrapeRequest | null = null,
  ) => {
    setLoading(true);
    setFeedback(null);

    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        password: adminPassword,
      },
    };

    if (!!body) {
      options.body = JSON.stringify(body);
    }

    const url = `${process.env.REACT_APP_API_URL}${apiPath}`;

    const response = await fetch(url, options);
    setLoading(false);

    if (response.status < 200 || response.status > 299) {
      setFeedback({
        type: "Negative",
        message: `${description} for ${
          hero.name
        } failed: ${await response.text()}`,
      });
      return;
    }

    setFeedback({
      type: "Positive",
      message: `${description} for ${
        hero.name
      } successful: ${await response.text()}`,
    });

    // Open new hero in new tab (if new hero)
    if (description === "Import" && !!body) {
      const win = window.open(`/Hero/${body.shortId}`, "_blank");
      win?.focus();
    }

    // Close the popup
    setImportAdditionalInfo({ ...defaultImportAdditionalInfo });

    // Remove the hero on success
    setScrapeList(scrapeList.filter((x) => x.externalId !== hero.externalId));
  };

  const missingExternalIdCount = siteData.heroes.filter(
    (x) => !x.externalSiteId,
  ).length;

  return (
    <>
      <PageTitle>Importable Heroes</PageTitle>

      {!adminPassword ? (
        <p>Please enter the admin password.</p>
      ) : (
        <>
          {!!scrapeList.length && !!missingExternalIdCount && (
            <p>Heroes without external IDs: {missingExternalIdCount}</p>
          )}

          {!!feedback && (
            <div>
              <Feedback feedback={feedback} />
            </div>
          )}

          {!!scrapeList.length ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>External ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {scrapeList.map((hero) => {
                    const buttonType =
                      hero.externalId === undefined
                        ? "none"
                        : hero.externalId > linkOrImportTurnPoint
                        ? "import"
                        : "link";

                    return (
                      <tr key={hero.externalId}>
                        <td>{hero.name}</td>
                        {buttonType !== "none" ? (
                          <>
                            <td>
                              <a href={hero.url ?? ""}>{hero.externalId}</a>
                            </td>
                            <td>
                              {buttonType == "link" && (
                                <button
                                  onClick={() => {
                                    linkExistingHero(hero);
                                  }}
                                >
                                  Link
                                </button>
                              )}
                              {buttonType == "import" && (
                                <button
                                  onClick={() => {
                                    setImportAdditionalInfo({
                                      externalId: hero.externalId ?? 0,
                                      shortId: "",
                                    });
                                  }}
                                >
                                  Import
                                </button>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>Unknown</td>
                            <td>Link not yet available</td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Modal isOpen={!!importAdditionalInfo.externalId}>
                <h3>
                  Import:{" "}
                  {
                    scrapeList.filter(
                      (x) => x.externalId === importAdditionalInfo.externalId,
                    )[0]?.name
                  }
                </h3>

                <div style={{ height: "75%" }}>
                  <h4>Required</h4>
                  <div>
                    <TextDataField
                      label="Short ID"
                      value={importAdditionalInfo.shortId}
                      onChange={(value) => {
                        setImportAdditionalInfo({
                          ...importAdditionalInfo,
                          shortId: value.toLowerCase(),
                        });
                      }}
                    />
                  </div>

                  <h4>Optional</h4>
                  <div>
                    <SelectDataField
                      label="Theme"
                      value={importAdditionalInfo.themeId ?? ""}
                      onChange={(value) => {
                        setImportAdditionalInfo({
                          ...importAdditionalInfo,
                          themeId: !!value ? parseInt(value) : undefined,
                        });
                      }}
                      options={[
                        {
                          text: "",
                          value: "",
                        },
                        ...siteData.heroThemes.filter(x => !x.finished).map((x): SelectDataFieldItem => {
                          return {
                            text: x.name,
                            value: x.id,
                          };
                        }),
                      ]}
                    />
                    <DatepickerDataField
                      label={"First available"}
                      value={importAdditionalInfo.firstAvailableDate}
                      onChange={(date) => {
                        if (!!date) {
                          date.setHours(7);
                        }
                        setImportAdditionalInfo({
                          ...importAdditionalInfo,
                          firstAvailableDate: date ?? undefined,
                        });
                      }}
                    />
                    <TextDataField
                      label="Backup character name"
                      value={importAdditionalInfo.backupCharacterName ?? ""}
                      onChange={(value) => {
                        setImportAdditionalInfo({
                          ...importAdditionalInfo,
                          backupCharacterName: value,
                        });
                      }}
                    />
                    <SelectDataField
                      label="Backup character game"
                      value={importAdditionalInfo.backupCharacterGame ?? ""}
                      onChange={(value) => {
                        setImportAdditionalInfo({
                          ...importAdditionalInfo,
                          backupCharacterGame: parseInt(value),
                        });
                      }}
                      options={[
                        {
                          text: "",
                          value: "",
                        },
                        ...siteData.games.map((x): SelectDataFieldItem => {
                          return {
                            text: x.title,
                            value: x.id,
                          };
                        }),
                      ]}
                    />
                  </div>
                </div>

                <div>
                  <button
                    disabled={!importAdditionalInfo.shortId}
                    onClick={() => {
                      importNewHero(
                        scrapeList.filter(
                          (x) =>
                            x.externalId === importAdditionalInfo.externalId,
                        )[0],
                      );
                    }}
                  >
                    Import
                  </button>
                  <button
                    onClick={() =>
                      setImportAdditionalInfo(defaultImportAdditionalInfo)
                    }
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </>
          ) : (
            <p>No heroes available to import.</p>
          )}
        </>
      )}
    </>
  );
};

export default ImportIndex;
